<template>
  <div class="mt-3">
    <sgv-table
      :items="orders"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :toolbars="toolbars">

      <template slot="option" v-if="options.toolbar === 'invoice'">
        <DetailOrderAvailable
          ref="orderAvailable"
          class="mb-3"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          @updated="orderList">
        </DetailOrderAvailable>
      </template>

      <template slot-scope="row">
        <DetailOrderSubItem
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :row="row"
          :canDestroy="!formData.closedAt && ['invoice'].includes(options.toolbar)"
          @updated="orderDeleted">
        </DetailOrderSubItem>
      </template>

      <div class="" slot="action">
        <sgv-input-text
          class="my-md-0"
          disabled
          :value="totalPayment">
        </sgv-input-text>
      </div>
    </sgv-table>
  </div>
</template>

<script>
import round from 'lodash/round'
import DetailOrderAvailable from './DetailOrderAvailable'
import DetailOrderSubItem from './DetailOrderSubItem.vue'
import {
  LIST_ORDER,
  WATCH_DOC_UPDATED
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      orders: [],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'parentId', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      }
    }
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: 'วันที่จัดส่ง | ครบกำหนด', value: 'parentId'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      const arr = [
        {value: 'invoice', icon: 'truck', class: 'text-warning'}
      ]
      return arr
    },
    ledgersSum () {
      return this.orders.reduce((t,v) => {
        const x = t + v.ledgers.reduce((sub, ledger) => {
          return sub + Number(ledger.amount)
        }, 0)
        return round(x, 2)
      }, 0)
    },
    totalPayment () {
      const total = this.orders.filter(v => v.type === 'badDebt').reduce((t,v) => {
        t += +v.totalPrice
        return round(t, 2)
      }, 0)

      return `ยอดรวม: ${total}`
    }
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.orderList()
        }
      },
    },
  },
  methods: {
    orderList () {
      this.$apollo.query({
        query: LIST_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.orders = res.data.orders
      })
      .catch(() => {
        this.orders = []
      })
    },
    orderDeleted () {
      this.orderList()
      this.$refs.orderAvailable.refetchList()
    }
  },
  watch: {
    'formData.approvedAt': {
      handler () {
        this.options.toolbar = null
      }
    }
  },
  created () {
    this.orderList()
  },
  components: {
    DetailOrderAvailable,
    DetailOrderSubItem
  }
}
</script>

<style lang="css" scoped>
</style>
