<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-date
          label="วันที่บันทึก"
          v-model="formData.closedDate"
          class="col-6"
          :validations="[
            {text: 'required!', value: $v.formData.closedDate.$dirty && $v.formData.closedDate.$error}
          ]">
        </sgv-input-date>
      </div>
    </div>

  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CLOSE_DOC } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      payments: [],
      formData: {
        closedDate: this.$date.current().date,
      }
    }
  },
  validations: {
    formData: {
      closedDate: {required},
    }
  },
  methods: {
    closeData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CLOSE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          closedDate: this.formData.closedDate
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("จบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
