import gql from 'graphql-tag'


export const LIST_ORDER_AVAILABLE = (templateType) => gql`query LIST_ORDER_AVAILABLE ($docType: String!, $contactId: Int, $q: FilterInput) {
  orders: listDoc${templateType}OrderAvailable (docType: $docType, contactId: $contactId, q: $q) {
    id totalPrice dueAt remaining
    invoice {
      id type code name contactId
      approvedBy closedBy
    }
    salesorder {
      id type code name contactId
    }
  }
}`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {
    id type name
    qty price totalPrice
    unitId unit {id name}
    docId doc {id code}
    ledgers {
      id docId stockId orderId amount
      accountId account {id code name}
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
    parentId parent {
      id
      docId doc {
        id code approvedAt closedAt
      }
      parentId parent {
        id
        docId doc {
          id code approvedAt closedAt salesCredit
        }
      }
    }
    children {
      id
      docId doc {
        id code approvedAt closedAt
      }
    }
  }
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $orderId: Int!, $totalPrice: Float!) {
  createOrder: createDoc${templateType}Order (docType: $docType, docId: $docId, orderId: $orderId, totalPrice: $totalPrice) {
    id
  }
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {
    id
  }
}`
